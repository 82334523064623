import i18n from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { initReactI18next } from 'react-i18next';
import { useParams } from 'react-router-dom';
import LanguageDetector from 'i18next-browser-languagedetector';

// 번역 리소스
const translations = {
	en: {
		translation: {
			text: 'Doob',
		},
	},
	ko: {
		translation: {
			text: '두브',
		},
	},
};

i18n
	.use(initReactI18next) // react-i18next 모듈 사용
	.use(LanguageDetector) // 언어 감지기 사용
	.init({
		resources: translations,
		fallbackLng: 'en', // 기본 언어 설정
		detection: {
			order: [
				'path',
				'querystring',
				'cookie',
				'localStorage',
				'navigator',
				'htmlTag',
			],
			caches: ['cookie'],
		},
		interpolation: {
			escapeValue: false, // XSS 방지 비활성화 (React는 기본적으로 방지)
		},
	});

export function usePageTranslations(translations) {
	// const { lang: language } = useParams();
	const [namespace] = useState(
		() => `namespace_${Math.random().toString(36).substr(2, 9)}`
	);

	const [loaded, setLoaded] = useState(false);
	useEffect(() => {
		// 언어별로 번역 리소스 추가
		Object.keys(translations).forEach((lang) => {
			i18n.addResourceBundle(lang, namespace, translations[lang], true, true);
		});

		setLoaded(true);

		return () => {
			// 컴포넌트 언마운트 시 리소스 제거
			Object.keys(translations).forEach((lang) => {
				i18n.removeResourceBundle(lang, namespace);
			});
		};
	}, [namespace]); // namespace가 변경되면 훅을 재실행

	const nt = useCallback(
		(key) => {
			if (!loaded) return '';
			return i18n.t(key, { ns: namespace });
		},
		[namespace, loaded]
	);

	return { ...i18n, nt };
}

export default i18n;
