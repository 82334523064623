import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { usePageTranslations } from '../../utils/translation';

const translations = {
    en: {
        expertise: 'Expertise',
        expertiseDes: 'With years of experience and expertise, we deliver industry-leading solutions.',
        tailoredServices: 'Tailored Services',
        tailoredServicesDes: 'We provide customized solutions tailored to each client\'s unique business needs.',
        reliability: 'Reliability',
        reliabilityDes: 'We contribute to our clients\' success with reliable and consistent services.'
    },
    ko: {
        expertise: '전문성',
        expertiseDes: '오랜 경험과 전문성을 바탕으로, 업계 최고의 솔루션을 제공합니다.',
        tailoredServices: '맞춤형 서비스',
        tailoredServicesDes: '각 고객의 비즈니스 요구에 맞춰 최적화된 맞춤형 솔루션을 제공합니다.',
        reliability: '신뢰성',
        reliabilityDes: '안정적이고 신뢰할 수 있는 서비스로 고객의 성공을 함께 만들어 갑니다.'
    }
};


const AdvanceTabTwo = () => {
    const { t, nt } = usePageTranslations(translations);
    return (
        <div>
            <Tabs>
                <div className="row row--30">
                    <div className="col-lg-8">
                        <div className="advance-tab-content advance-tab-content-1">
                            <TabPanel>
                                <div className="tab-content">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <img src="/images/tab/expert.jpg" alt="Corporate Images" />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            
                            <TabPanel>
                                <div className="tab-content">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <img src="/images/tab/tailored.jpg" alt="Corporate Images" />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="tab-content">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <img src="/images/tab/reliability.jpg" alt="Corporate Images" />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </div>
                    </div>
                    <div className="col-lg-4 mt_md--30 mt_sm--30">
                        <div className="advance-tab-button advance-tab-button-1 right-align">
                            <TabList className="tab-button-list">
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">{nt('expertise')}</h4>
                                        <p className="description">{nt('expertiseDes')}</p>
                                    </div>   
                                </Tab>
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">{nt('tailoredServices')}</h4>
                                        <p className="description">{nt('tailoredServicesDes')}</p>
                                    </div>   
                                </Tab>
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">{nt('reliability')}</h4>
                                        <p className="description">{nt('reliabilityDes')}</p>
                                    </div>   
                                </Tab>
                            </TabList>
                        </div>
                    </div>
                    
                </div>
            </Tabs>
        </div>
    )
}
export default AdvanceTabTwo;
