import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { usePageTranslations } from '../../utils/translation';

const TimelineData = [
    {
        id: "1",
        title: "Knowledge",
        description: "Present all available features in Essentials.",
    },
    {
        id: "2",
        title: "Working",
        description: "All Feature available features in Essentials.",
    },
    {
        id: "3",
        title: "Solution",
        description: "Popular Feature available features in Essentials.",
    },
    {
        id: "4",
        title: "Process",
        description: "Latest Feature available features in Essentials.",
    },
]

const translations = {
    en: {
        step1: 'Requirement Analysis',
        step1Des: 'Understanding the project goals and requirements to find the optimal solution.',
        step2: 'Architecture Design',
        step2Des: 'Designing the system structure and data flow based on the analyzed requirements.',
        step3: 'Development and Testing',
        step3Des: 'Developing and testing the solution based on the designed architecture to ensure quality.',
        step4: 'Deployment and Support',
        step4Des: 'Deploying the solution and providing support for stable operations.'
    },
    ko: {
        step1: '요구 사항 분석',
        step1Des: '최적의 솔루션을 위해 프로젝트 목표와 비즈니스 요구 사항 파악',
        step2: '아키텍처 설계',
        step2Des: '요구 사항을 바탕으로 시스템의 구조와 데이터 흐름을 설계',
        step3: '개발 및 테스트',
        step3Des: '품질보장을 위해 설계된 아키텍처를 바탕으로 개발하고 테스트',
        step4: '배포 및 지원',
        step4Des: '솔루션을 배포하고 안정적인 운영을 위한 지원 제공'
    }
};


const TimelineTwo = ({classVar}) => {
    const { t, nt } = usePageTranslations(translations); 
    return (
        <div className="timeline-style-two bg-color-blackest">
            <div className="row row--0">
                {/* Start Single Progress */}
                {[1,2,3,4].map((index) =>(
                    <div className={`col-lg-3 col-md-3 rn-timeline-single ${classVar}`} key={index}>
                        <div className="rn-timeline">
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <h6 className="title">{nt(`step${index}`)}</h6>
                            </ScrollAnimation>
                            <div className="progress-line">
                                <div className="line-inner"></div>
                            </div>
                            <div className="progress-dot">
                                <div className="dot-level">
                                    <div className="dot-inner"></div>
                                </div>
                            </div>
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <p className="description">{nt(`step${index}Des`)}</p>
                            </ScrollAnimation>
                        </div>
                    </div>
                ))}
                {/* End Single Progress */}
            </div>
        </div>
    )
}
export default TimelineTwo;