import React from 'react';
import { FiActivity, FiCast, FiMap, FiCode } from "react-icons/fi";
import { IoHardwareChipOutline } from 'react-icons/io5';
import { PiTreeStructureLight } from "react-icons/pi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { usePageTranslations } from '../../utils/translation';

// const ServiceList = [
//     {
//         icon: <FiActivity />,
//         title: 'Awarded Design',
//         description: 'There are many variations variations  of passages of Lorem Ipsum available, but the majority have suffered.'
//     },
//     {
//         icon: <FiCast />,
//         title: 'Design & Creative',
//         description: 'Passages there are many variations variations  of of Lorem Ipsum available, but the majority have suffered.'
//     },
//     {
//         icon: <FiMap />,
//         title: 'App Development',
//         description: 'Variations There are many variations  of passages of Lorem Ipsum available, but the majority have suffered.'
//     },
// ]

const translations = {
	en: {
        softwareTitle: 'S/W DEVELOPMENT',
        softwareDes: 'Multiple languages enable software development on numerous platforms.',
        embeddedTitle: 'EMBEDDED HARDWARE',
        embeddedDes: 'Take full advantage of embedded hardware and combine it with software.',
        infraTitle: 'INFRASTRUCTURE',
        infraDes: 'Consult and build your infrastructure with our Specialized Engineers.',
	},
	ko: {
        softwareTitle: '소프트웨어 개발',
        softwareDes: '다양한 프로그래밍 언어들을 이용해 모든 플랫폼들에 대한 소프트웨어 배포가 가능합니다.',
        embeddedTitle: '임베디드 하드웨어',
        embeddedDes: '직접 개발한 각종 임베디드 하드웨어는 당사의 소프트웨어와 결합하여 높은 개발성을 지닙니다.',
        infraTitle: '인프라스트럭처',
        infraDes: '당사의 전문가를 통해 상담받고 서버, 데이터센터, NAS등 솔루션을 구축하세요.',
	},
};

const ServiceOne = ({textAlign, serviceStyle}) => {
    const { t, nt } = usePageTranslations(translations);

    // 서비스 목록
    const ServiceList = [
        {
            icon: <FiCode />,
            title: nt('softwareTitle'),
            description: nt('softwareDes'),
        },
        {
            icon: <IoHardwareChipOutline />,
            title: nt('embeddedTitle'),
            description: nt('embeddedDes'),
        },
        {
            icon: <PiTreeStructureLight />,
            title: nt('infraTitle'),
            description: nt('infraDes'),
        },
    ];
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceOne;