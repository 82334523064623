import React from 'react';
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import BreadcrumbOne from "../elements/breadcrumb/BreadcrumbOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import { usePageTranslations } from '../utils/translation';

const translations = {
    en: {
        seoTitle: 'Terms & Condition',
        acceptanceOfTermsTitle: '1. Acceptance of Terms',
        acceptanceOfTermsContent: 'By accessing and using this website/service, you accept and agree to be bound by the terms and provisions of this agreement.',
        modificationOfTermsTitle: '2. Modification of Terms',
        modificationOfTermsContent: 'We reserve the right to change, modify, or revise these terms at any time. It is the user\'s responsibility to review these terms periodically.',
        userConductTitle: '3. User Conduct',
        userConductContent: 'Users agree not to use the service for any unlawful purposes or to conduct any unlawful activity.',
        accountResponsibilitiesTitle: '4. Account Responsibilities',
        accountResponsibilitiesContent: 'Users are responsible for maintaining the confidentiality of their account and password and for restricting access to their computer.',
        terminationTitle: '5. Termination',
        terminationContent: 'We may terminate or suspend access to our service immediately, without prior notice, for any reason, including without limitation if you breach the terms.',
        dataPrivacyTitle: '6. Data & Privacy',
        dataPrivacyContent: 'All data collected from the users will be stored securely. Our privacy policy details how user data is handled.',
        intellectualPropertyTitle: '7. Intellectual Property',
        intellectualPropertyContent: 'All content provided on this site is our property or the property of our content suppliers and protected by international copyright laws.',
        limitationOfLiabilityTitle: '8. Limitation of Liability',
        limitationOfLiabilityContent: 'In no event shall we be liable for any incidental, special, consequential, or indirect damages of any kind.',
        governingLawTitle: '9. Governing Law',
        governingLawContent: 'This agreement is governed by the laws of South Korea.',
        contactTitle: '10. Contact',
        contactContent: 'For any questions regarding these terms, please contact us at support@alpaon.com.'
    },
    ko: {
        seoTitle: '이용약관',
        acceptanceOfTermsTitle: '1. 이용 약관 동의',
        acceptanceOfTermsContent: '이 웹사이트/서비스에 접근하고 사용함으로써, 본 계약의 약관과 조항에 구속되는 것에 동의하게 됩니다.',
        modificationOfTermsTitle: '2. 약관 변경',
        modificationOfTermsContent: '우리는 언제든지 약관을 변경, 수정, 개정할 권리를 보유합니다. 사용자는 주기적으로 약관을 검토할 책임이 있습니다.',
        userConductTitle: '3. 사용자 행동',
        userConductContent: '사용자는 서비스를 불법 목적으로 사용하거나 불법 활동을 수행하지 않기로 동의합니다.',
        accountResponsibilitiesTitle: '4. 계정 책임',
        accountResponsibilitiesContent: '사용자는 계정과 비밀번호의 기밀을 유지하고 자신의 컴퓨터 접근을 제한할 책임이 있습니다.',
        terminationTitle: '5. 서비스 이용 중지',
        terminationContent: '우리는 어떠한 이유로든, 사전 통지 없이 즉시 서비스 접근을 중지하거나 정지시킬 수 있습니다.',
        dataPrivacyTitle: '6. 데이터 및 개인정보',
        dataPrivacyContent: '사용자로부터 수집된 모든 데이터는 안전하게 보관됩니다. 개인정보 처리 방침은 사용자 데이터가 어떻게 처리되는지 상세히 설명합니다.',
        intellectualPropertyTitle: '7. 지적 재산권',
        intellectualPropertyContent: '이 사이트에서 제공하는 모든 콘텐츠는 우리의 재산이거나 콘텐츠 공급자의 재산이며 국제 저작권 법에 의해 보호됩니다.',
        limitationOfLiabilityTitle: '8. 책임의 제한',
        limitationOfLiabilityContent: '어떠한 경우에도 우리는 우연히 발생하는, 특별한, 결과적 또는 간접적 손해에 대해 책임을 지지 않습니다.',
        governingLawTitle: '9. 준거법',
        governingLawContent: '본 계약은 대한민국 법률에 의해 규율됩니다.',
        contactTitle: '10. 연락처',
        contactContent: '이 약관에 관한 질문은 support@alpaon.com으로 연락주세요.'
    }
};


const Terms = () => {
    const { t, nt } = usePageTranslations(translations); 

    // 이용약관의 모든 조항을 배열로 관리
    const termsSections = [
        { title: 'acceptanceOfTermsTitle', content: 'acceptanceOfTermsContent' },
        { title: 'modificationOfTermsTitle', content: 'modificationOfTermsContent' },
        { title: 'userConductTitle', content: 'userConductContent' },
        { title: 'accountResponsibilitiesTitle', content: 'accountResponsibilitiesContent' },
        { title: 'terminationTitle', content: 'terminationContent' },
        { title: 'dataPrivacyTitle', content: 'dataPrivacyContent' },
        { title: 'intellectualPropertyTitle', content: 'intellectualPropertyContent' },
        { title: 'limitationOfLiabilityTitle', content: 'limitationOfLiabilityContent' },
        { title: 'governingLawTitle', content: 'governingLawContent' },
        { title: 'contactTitle', content: 'contactContent' }
    ];

    return (
        <>
            <SEO title={nt('seoTitle')} />
            <Layout>
                <BreadcrumbOne 
                    title={nt('seoTitle')}
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Contact"
                />

                <div className="content" style={{ margin: '0 auto', maxWidth: '1200px', padding: '50px' }}>
                    {termsSections.map((section, index) => (
                        <div key={index}>
                            <h4><strong>{nt(section.title)}</strong></h4>
                            <p>{nt(section.content)}</p>
                        </div>
                    ))}
                </div>
            </Layout>
            
        </>
    )
}
export default Terms;