import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { usePageTranslations } from '../../utils/translation';

const translations = {
    en: {
        value1: 'User Experience',
        value1DesP1: 'We prioritize user-friendly interfaces to simplify navigation and increase productivity. Our design philosophy ensures that even new users can intuitively access features without difficulty.',
        value1DesP2: 'Through continuous feedback and iteration, we strive to refine the user experience, empowering all users to interact with our solutions effectively and efficiently.',
        
        value2: 'Seamless Integration',
        value2DesP1: 'Our solutions are engineered to seamlessly integrate with existing systems, minimizing disruptions to your workflow. We focus on providing smooth transitions.',
        value2DesP2: 'With comprehensive support, we make integration hassle-free, ensuring minimal setup time and immediate productivity gains.',
        
        value3: 'Versatility',
        value3DesP1: 'Our versatile solutions adapt to specific challenges, providing flexibility in diverse business environments. We build adaptable frameworks that cater to both current and future needs.',
        value3DesP2: 'No matter the industry or complexity of the requirement, our flexible solutions are designed to ensure that you achieve your goals efficiently and effectively.'
    },
    ko: {
        value1: '사용자 경험',
        value1DesP1: '알파온은 탐색을 단순화하고 생산성을 높이는 사용자 친화적인 인터페이스에 우선순위를 둡니다. 설계 철학을 통해 새로운 사용자도 기능을 쉽게 이용할 수 있도록 합니다.',
        value1DesP2: '지속적인 피드백과 반복적인 개선을 통해 사용자 경험을 개선하여 모든 사용자가 효과적이고 효율적으로 솔루션과 상호 작용할 수 있도록 합니다.',
        
        value2: '쉬운 통합',
        value2DesP1: '알파온의 솔루션은 기존 시스템과 매끄럽게 통합되어 작업 흐름에 지장을 주지 않습니다. 생산성을 유지할 수 있는 매끄러운 전환을 제공하여 구현 시에도 효율성을 유지합니다.',
        value2DesP2: '당사 전문가들의 포괄적인 지원을 통해 통합을 간단하게 하여 최소한의 설정 시간과 즉각적인 생산성 향상을 보장합니다.',
        
        value3: '범용성',
        value3DesP1: '알파온의 다목적 솔루션은 특정한 과제에 맞춰 조정되어 다양한 비즈니스 환경에서 유연성을 제공합니다. 현재와 미래의 요구를 모두 충족하는 적응형 프레임워크를 구축합니다.',
        value3DesP2: '산업이나 요구사항의 복잡도와 관계없이 우리의 유연한 솔루션은 목표를 효율적이고 효과적으로 달성할 수 있도록 설계되었습니다.'
    },
};



const TabTwo = () => {
    const { t, nt } = usePageTranslations(translations); 
    return (
        <div>
            <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <div className="row row--30 align-items-center">
                                <div className="col-lg-5">
                                    <img className="radius-small" src='/images/tab/core.jpg' alt="Corporate React Template" />
                                </div>
                                <div className="col-lg-7 mt_md--40 mt_sm--40">
                                    <div className="rn-default-tab style-two">
                                        <div className="tab-button-panel">
                                            <TabList className="tab-button">
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>{nt('value1')}</button>
                                                    </div>   
                                                </Tab>
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>{nt('value2')}</button>
                                                    </div>   
                                                </Tab>
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>{nt('value3')}</button>
                                                    </div>   
                                                </Tab>
                                            </TabList>
                                        </div>

                                        <div className="tab-content-panel">
                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <p>{nt('value1DesP1')}</p>
                                                        <p>{nt('value1DesP2')}</p>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            
                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <p>{nt('value2DesP1')}</p>
                                                        <p>{nt('value2DesP2')}</p>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <p>{nt('value3DesP1')}</p>
                                                        <p>{nt('value3DesP2')}</p>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
        </div>
    )
}

export default TabTwo
