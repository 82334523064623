import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect  } from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop';

// Pages import Here
import Landing from './pages/Landing';
import About from './pages/About';
import Error from './pages/Error';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';

// Elements import Here
import Contact from './elements/contact/Contact';

// Import Css Here
import './assets/scss/style.scss';

const App = () => {
	return (
		<Router>
			<PageScrollTop>
				<Switch>
					<Route
						path={`${process.env.PUBLIC_URL + '/:lang(en|ko)?/'}`}
						exact
						component={Landing}
					/>
					<Route
						path={`${process.env.PUBLIC_URL + '/:lang(en|ko)?/about'}`}
						exact
						component={About}
					/>
					<Route
						path={`${process.env.PUBLIC_URL + '/:lang(en|ko)?/contact'}`}
						exact
						component={Contact}
					/>
					<Route
						path={`${process.env.PUBLIC_URL + '/:lang(en|ko)?/terms'}`}
						exact
						component={Terms}
					/>
					<Route
						path={`${process.env.PUBLIC_URL + '/:lang(en|ko)?/privacy'}`}
						exact
						component={Privacy}
					/>
					<Route
						path="*"
						exact
						component={Error}
					/>
				</Switch>
			</PageScrollTop>
		</Router>
	);
};

export default App;
