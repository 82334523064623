import React from 'react';
import ContactForm from "./ContactForm";
import GoogleMapStyle from "./GoogleMapStyle";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";
import { usePageTranslations } from '../../utils/translation';

const translations = {
    en: {
        phone: 'Contact Phone Number',
        email: 'Our Email Address',
        addressTitle: 'Our Location',
        address1: 'E-802, 30, Songdomirae-ro,',
        address2: 'Yeonsu-gu, Incheon, South Korea',
    },
    ko: {
        phone: '전화',
        email: '이메일',
        addressTitle: '주소',
        address1: '인천광역시 연수구',
        address2: '송도미래로 30 E동 802호',
    }
};

const ContactOne = () => {
    const { t, nt } = usePageTranslations(translations); 
    return (
        <>
            <div className="row row--15">
                <div className="col-lg-12">
                    <div className="rn-contact-address mt_dec--30">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">{nt('phone')}</h4>
                                        <p><a href="tel:+1 212 380 1711">+1 212 380 1711</a></p>
                                        <p><a href="tel:+82 32 859 0458">+82 32 859 0458</a></p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">{nt('email')}</h4>
                                        <p><a href="mailto:support@alpaon.com">support@alpaon.com</a></p>
                                        <p><a href="mailto:sales@alpaon.com">sales@alpaon.com</a></p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">{nt('addressTitle')}</h4>
                                        <p>{nt('address1')} <br /> {nt('address2')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt--40 row--15">
                {/* <div className="col-lg-7">
                    <ContactForm formStyle="contact-form-1" />
                </div> */}
                <div className="col-lg-15 mt_md--30 mt_sm--30">
                    <GoogleMapStyle />
                </div>
            </div>
        </>
    )
}
export default ContactOne;