import React from 'react'
import Layout from "../common/Layout";
import { FaSistrix } from "react-icons/fa";
import { usePageTranslations } from '../utils/translation';

const translations = {
    en: {
        title: 'The page you requested cannot be found.',
        subtitle: 'Please check if the address is correct and try again.',
        button: 'Go Back Home',
    },
    ko: {
        title: '요청한 페이지를 찾을 수 없어요.',
        subtitle: '올바른 주소인지 확인 후 다시 시도해 주세요.',
        button: '홈으로 돌아가기',
    },
};

const Error = () => {
    const { t, nt } = usePageTranslations(translations);
    return (
        <Layout>
            <div className="error-area ptb--200 ptb_sm--60 ptb_md--80">
                <div className="container">
                    <div className="row align-item-center">
                        <div className="col-lg-12">
                            <div className="error-inner">
                                <h1>404</h1>
                                <h2 className="title">{nt('title')}</h2>
                                <p className="description">{nt('subtitle')}</p>
                                <div className="view-more-button">
                                    <a className="btn-default" href="/">{nt('button')}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Error;