import React from 'react'
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import AdvanceTabTwo from '../elements/advancetab/AdvanceTabTwo';
import CalltoActionFive from '../elements/calltoaction/CalltoActionFive';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import FooterOne from '../common/footer/FooterOne';
import Copyright from '../common/footer/Copyright';
import BrandFour from "../elements/brand/BrandFour";
import ServiceOne from '../elements/service/ServiceOne';
import { usePageTranslations } from '../utils/translation';

const translations = {
	en: {
		viewDemo: 'View Demo',
        custom: 'Custom Solutions',
        services: 'Services provide for you.',
        servicesDes: 'We comprehensively support every aspect of service development, from software development to hardware and infrastructure.',
        whyUsSub: 'Our Advantage',
        partnerTitle: 'Partner Companies',
        partnerSubtitle: 'Success Together',
        seoTitle: 'Home',
	},
	ko: {
		viewDemo: '데모 보기',
        custom: '맞춤형 솔루션',
        services: '제공하는 서비스들',
        servicesDes: '소프트웨어 개발부터 하드웨어와 인프라스트럭처까지, 서비스 개발을 위한 모든 영역을 포괄적으로 지원합니다.',
        whyUsSub: '알파온의 강점',
        partnerTitle: '함께하는 기업',
        partnerSubtitle: '성공의 동행',
        seoTitle: '홈',
	},
};

const Landing = () => {
    const { t, nt } = usePageTranslations(translations);    
    return (
        <>
            <SEO title={nt('seoTitle')} />
            <main className="page-wrapper">
                <HeaderOne btnStyle="btn-default btn-small btn-border" HeaderSTyle="header-not-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-850 bg_image" style={{ position: 'relative', overflow: 'hidden' }}>
                    <video autoPlay muted loop playsInline style={{
                        position: 'absolute',
                        width: '100%',
                        left: '0',
                        top: '0',
                        height: '100%',
                        objectFit: 'cover',
                        zIndex: -1
                    }}>
                        <source src={`${process.env.PUBLIC_URL}/images/bg/bg-home.mp4`} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">Infinite Alpha</span></h3>
                                    </div>
                                    <h1 className="title display-one" style={{ fontWeight: '900' }}>We are<br />Alpaon</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}



                <Separator />
                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = {nt('custom')}
                                    title = {nt('services')}
                                    description = {nt('servicesDes')}
                                    />
                            </div>
                        </div>

                        <ServiceOne 
                            serviceStyle = "service__style--1 icon-circle-style"
                            textAlign = "text-center"
                            />
                    </div>
                </div>
                {/* End Service Area  */}

                {/* Start Elements Area  */}
                <div className="rwt-advance-tab-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--40">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = {nt('whyUsSub')}
                                    title = "Why us?"
                                    description = ""
                                />
                            </div>
                        </div>
                        <AdvanceTabTwo />
                    </div>
                </div>
                {/* End Elements Area  */}


                <Separator />

                {/* Start Brand Area  */}
                <div className="rwt-brand-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = {nt('partnerSubtitle')}
                                    title = {nt('partnerTitle')}
                                    description = ""
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt--10">
                                <BrandFour brandStyle="brand-style-2 variation-2" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}
                


                {/* Start Elements Area  */}
                <div className="rwt-callto-action-area rn-section-gapBottom">
                    <div className="wrapper">
                        <CalltoActionFive />
                    </div>
                </div>
                {/* End Elements Area  */}

                <FooterOne />
                <Copyright />
            </main>

        </>
    )
}

export default Landing
