import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import SectionTitle from "../sectionTitle/SectionTitle";
import ContactOne from "./ContactOne";
import { usePageTranslations } from '../../utils/translation';

const translations = {
    en: {
        subTitle: 'Contact Now',
        title: 'Our Contact Information',
        seoTitle: 'Contact',
    },
    ko: {
        subTitle: '지금 연락하세요',
        title: '문의하기',
        seoTitle: '문의하기',
    }
};

const Contact = () => {
    const { t, nt } = usePageTranslations(translations); 
    return (
        <>
            <SEO title={nt('seoTitle')} />
            <Layout>
                {/* <BreadcrumbOne 
                    title="The Easiest Way To Create Website <br /> Quick Copy & Make site."
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Contact"
                /> */}
                <div className="main-content">
                    {/* Start Contact Area  */}
                    <div className="rwt-contact-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--40">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = {nt('subTitle')}
                                        title = {nt('title')}
                                        description = ""
                                    />
                                </div>
                            </div>
                            <ContactOne />
                        </div>
                    </div>
                    {/* End Contact Area  */}
                </div>
            </Layout>
        </>
    )
}
export default Contact;