import React from 'react'
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import FooterOne from '../common/footer/FooterOne';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import TeamOne from "../elements/team/TeamOne";
import TabTwo from "../elements/tab/TabTwo";
import SplitOne from "../elements/split/SplitOne";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";
import { usePageTranslations } from '../utils/translation';

const translations = {
    en: {
        tabTitle: 'Our Core Values',
        tabSubtitle: 'Team Spirit'
    },
    ko: {
        tabTitle: '핵심 가치',
        tabSubtitle: '팀 정신'
    }
};



const About = () => {
    const { t, nt } = usePageTranslations(translations); 
    return (
        <>
            <SEO title="About" />
            <main className="page-wrapper">
                <HeaderOne btnStyle="btn-default btn-small btn-border" HeaderSTyle="header-not-transparent" />

                {/* Start Slider area  */}
                <div className="slider-area slider-style-2 variation-2  height-850 bg_image" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-about.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-xl-6">
                                <div className="inner text-start">
                                    <h1 className="title display-one" style={{ fontWeight: '900' }}>About Us</h1>
                                    <h6 className="tag-title">WE ALPHA ON YOUR LIFE</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider area  */}
                
                {/* Start Elements Area  */}
                <div className="rwt-tab-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--40">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = {nt('tabSubtitle')}
                                    title = {nt('tabTitle')}
                                    description = ""
                                />
                            </div>
                        </div>
                        <TabTwo />
                    </div>
                </div>
                {/* End Elements Area  */}

                <Separator />


                <div className="rwt-timeline-area rn-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <TimelineTwo classVar="no-gradient"  />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Start Elements Area  */}
                <div className="rwt-split-area rn-section-gap">
                    <div className="wrapper">
                        <SplitOne />
                    </div>
                </div>
                {/* End Elements Area  */}

                {/* Start Elements Area  */}
                <div className="rwt-team-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Skilled Experts"
                                        title = "Our Executives"
                                        description = ""
                                    />
                            </div>
                        </div>
                        <TeamOne column="col-lg-4 col-md-6 col-12 mt--30" teamStyle="team-style-default" />
                    </div>
                </div>
                {/* End Elements Area  */}






                <FooterOne />
            </main>
        </>
    )
}

export default About;
