import React from 'react';
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import BreadcrumbOne from "../elements/breadcrumb/BreadcrumbOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import { usePageTranslations } from '../utils/translation';

const translations = {
    en: {
        seoTitle: 'Privacy Policy',
        privacy1Title: '1. Purpose',
        privacy1Content: 'This privacy policy defines the standards that Alpaon LLC (hereinafter referred to as the "Company" or "AlphaConnect") adheres to in order to protect the information of users of its SaaS service and to effectively and promptly address related grievances.',
        privacy2Title: '2. Collection and Use of Personal Information',
        privacy2Content: 'The Company collects and uses personal information for the following purposes: Service provision and contract fulfillment, Handling customer inquiries and complaints, Service usage and usage statistics.',
        privacy2Content2: 'We partner with Microsoft Clarity and Microsoft Advertising to capture how you use and interact with our website through behavioral metrics, heatmaps, and session replay to improve and market our products/services. Website usage data is captured using first and third-party cookies and other tracking technologies to determine the popularity of products/services and online activity. Additionally, we use this information for site optimization, fraud/security purposes, and advertising. For more information about how Microsoft collects and uses your data, visit the Microsoft Privacy Statement.',
        privacy3Title: '3. Information Collected',
        privacy3Content: 'We only collect the minimum necessary information required for service use, such as email addresses, contact details, and company names.',
        privacy4Title: '4. Retention and Use Period of Personal Information',
        privacy4Content: 'The Company, in principle, destroys the information without delay once the purpose of collecting and using the personal information is achieved. However, if there is a need to retain it due to legal provisions, the Company will keep the member information for a certain period.',
        privacy5Title: '5. Procedure and Method of Destroying Personal Information',
        privacy5Content: 'Information in electronic file form is deleted using a technical method that cannot reproduce the record.',
        privacy6Title: '6. Provision of Personal Information to Third Parties',
        privacy6Content: 'The Company does not provide users\' personal information to outsiders in principle. However, exceptions are made in the following cases: When users have given prior consent, When provided in accordance with legal provisions.',
        privacy7Title: '7. User Rights and Obligations',
        privacy7Content: 'Users can view or modify their personal information at any time and can request the Company to delete their personal information.',
        privacy8Title: '8. Changes to the Privacy Policy',
        privacy8Content: 'This privacy policy may be added to, deleted, or modified due to changes in laws, policies, or security technologies. Changes will be notified through website announcements.',
        privacy9Title: '9. Inquiries',
        privacy9Content: 'For inquiries regarding this policy, please contact support@alpaon.com.',
    },
    ko: {
        seoTitle: '개인정보 처리방침',
        privacy1Title: '1. 목적',
        privacy1Content: '본 개인정보처리방침은 알파온 유한책임회사(이하 "회사" 또는 "알파커넥트")에서 운영하는 SaaS 서비스의 이용자들의 정보를 보호하고, 이와 관련된 고충을 효과적이고 신속하게 처리할 수 있도록 하기 위해 준수하는 방침을 정의합니다.',
        privacy2Title: '2. 개인정보의 수집 및 이용목적',
        privacy2Content: '회사는 다음의 목적을 위해 개인정보를 수집 및 이용합니다: 서비스 제공 및 계약 이행, 고객 문의 및 불만 처리, 서비스 이용 및 이용 통계.',
        privacy2Content2: '당사는 Microsoft Clarity 및 Microsoft Advertising과 협력하여 행동 지표, 히트맵 및 세션 재생을 통해 귀하가 당사 웹사이트를 사용하고 상호 작용하는 방식을 파악하여 제품/서비스를 개선하고 마케팅합니다. 웹사이트 사용 데이터는 제1자 및 제3자 쿠키와 기타 추적 기술을 사용하여 캡처되어 제품/서비스 및 온라인 활동의 인기도를 결정합니다. 또한 당사는 사이트 최적화, 사기/보안 목적 및 광고를 위해 이 정보를 사용합니다. Microsoft가 귀하의 데이터를 수집하고 사용하는 방법에 대한 자세한 내용을 보려면 Microsoft 개인정보취급방침을 방문하세요.',
        privacy3Title: '3. 수집하는 개인정보의 항목',
        privacy3Content: '이메일 주소, 연락처, 사용 회사명 등 서비스 이용에 필요한 최소한의 정보만 수집합니다.',
        privacy4Title: '4. 개인정보의 보유 및 이용기간',
        privacy4Content: '회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 단, 관계 법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 일정 기간 동안 회원 정보를 보관합니다.',
        privacy5Title: '5. 개인정보의 파기절차 및 방법',
        privacy5Content: '전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.',
        privacy6Title: '6. 개인정보 제3자 제공',
        privacy6Content: '회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 단, 다음의 경우는 예외로 합니다: 이용자들이 사전에 동의한 경우, 법령의 규정에 따라 제공하는 경우.',
        privacy7Title: '7. 이용자의 권리와 의무',
        privacy7Content: '이용자는 언제든지 자신의 개인정보를 조회하거나 수정할 수 있으며, 회사에 대하여 자신의 개인정보를 삭제하도록 요구할 수 있습니다.',
        privacy8Title: '8. 개인정보처리방침 변경',
        privacy8Content: '이 개인정보처리방침은 법령, 정책 또는 보안기술의 변경에 따라 내용의 추가, 삭제 및 수정이 있을 수 있습니다. 변경 사항은 웹사이트 공고를 통해 통지됩니다.',
        privacy9Title: '9. 문의',
        privacy9Content: '본 방침에 관한 문의는 support@alpaon.com으로 부탁드립니다.',
    }
};



const Privacy = () => {
    const { t, nt } = usePageTranslations(translations); 

    // 이용약관의 모든 조항을 배열로 관리
    const privacySections = [
        'privacy1', 'privacy2', 'privacy3', 'privacy4', 'privacy5', 
        'privacy6', 'privacy7', 'privacy8', 'privacy9'
    ];

    // 주어진 섹션에 대해 컨텐츠를 렌더링하는 함수
    const renderContent = (section) => {
        const contentKey = `${section}Content`;
        let contents = [];

        // 첫 번째 컨텐츠 렌더링
        let firstContent = nt(contentKey);
        if (firstContent !== contentKey) {
            contents.push(<p key={contentKey}>{firstContent}</p>);
        }

        // 두 번째 컨텐츠 렌더링 (contentKey2)
        let secondContentKey = `${contentKey}2`;
        let secondContent = nt(secondContentKey);
        if (secondContent !== secondContentKey) {
            contents.push(<p key={secondContentKey}>{secondContent}</p>);
        }

        return contents;
    };

    return (
        <>
            <SEO title={nt('seoTitle')} />
            <Layout>
                <BreadcrumbOne 
                    title={nt('seoTitle')}
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Contact"
                />
                
                <div className="content" style={{ margin: '0 auto', maxWidth: '1200px', padding: '50px' }}>
                {privacySections.map((section, index) => (
                    <div key={index}>
                        <h4><strong>{nt(`${section}Title`)}</strong></h4>
                        {renderContent(section)}
                    </div>
                ))}
                </div>
            </Layout>
            
        </>
    )
}
export default Privacy;