import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { usePageTranslations } from '../../utils/translation';

const translations = {
    en: {
        title: 'Infinite Alpha',
        des: 'Like a sine wave, Alpaon can bring endless possibilities. We will \'Alpha\' on your life, work, or any other situation.',
        des1: '- Continuous Innovation: Expanding the boundaries of technology.',
        des2: '- Personalized Solutions: Providing tailored solutions.',
        des3: '- Enhanced Efficiency: Boosting work efficiency.',
        des4: '- Expanding Possibilities: Exploring new possibilities.'
    },
    ko: {
        title: 'Infinite Alpha',
        des: '사인파처럼 Alpaon은 무한한 가능성을 가져올 수 있습니다. 사용자의 삶, 일 또는 기타 모든 상황에 대해 \'Alpha\'할 것입니다.',
        des1: '- 지속적 혁신: 기술의 경계를 확장합니다.',
        des2: '- 개인 맞춤 솔루션: 맞춤형 솔루션을 제공합니다.',
        des3: '- 업무 효율성 향상: 업무 효율을 증대시킵니다.',
        des4: '- 가능성의 확장: 새로운 가능성을 탐험합니다.'
    }
};


const SplitOne = () => {
    const { t, nt } = usePageTranslations(translations); 
    return (
            <div className="rn-splite-style">
                <div className="split-wrapper">
                        <div className="row no-gutters radius-10 align-items-center">
                            <div className="col-lg-12 col-xl-6 col-12">
                                <div className="thumbnail image-left-content">
                                    <img src="/images/logo-long.svg" alt="split Images" />
                                </div>
                            </div>
                            <div className="col-lg-12 col-xl-6 col-12">
                                <div className="split-inner">
                                    <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                        <h4 className="title">{nt('title')}</h4>
                                    </ScrollAnimation>
                                    <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                    <p className="description">{nt('des')}</p>
                                    </ScrollAnimation>

                                    
                                    <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                        <ul className="split-list">
                                            <li>{nt('des1')}</li>
                                            <li>{nt('des2')}</li>
                                            <li>{nt('des3')}</li>
                                            <li>{nt('des4')}</li>
                                        </ul>
                                    </ScrollAnimation>
                                    <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                    </ScrollAnimation>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default SplitOne
