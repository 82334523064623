import React from 'react'
import { usePageTranslations } from '../../utils/translation';

const translations = {
	en: {
		privacy: 'Privacy Policy',
        terms: 'Terms & Condition',
        contact: 'Contact Us',
        copyright: '© Alpaon LLC',
	},
	ko: {
		privacy: '개인정보 처리방침',
        terms: '이용약관',
        contact: '문의하기',
        copyright: '© 알파온 유한책임회사',
	},
};

const Copyright = () => {
    const { t, nt } = usePageTranslations(translations);
    return (
        <div className="copyright-area copyright-style-one">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-8 col-sm-12 col-12">
                        <div className="copyright-left">
                            <ul className="ft-menu link-hover">
                                <li><a href="privacy">{nt('privacy')}</a></li>
                                <li><a href="terms">{nt('terms')}</a></li>
                                <li><a href="/contact">{nt('contact')}</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-4 col-sm-12 col-12">
                        <div className="copyright-right text-center text-md-end">
                            <p className="copyright-text">{nt('copyright')} {new Date().getFullYear()}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Copyright;