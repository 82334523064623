import React from 'react';
import { FiGlobe, FiLinkedin, FiGithub } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { usePageTranslations } from '../../utils/translation';

const translations = {
    en: {
        name1: 'Seongyun Specter Ku',
        designation1: 'Founder, CEO',
        description1: 'State University of New York, Stony Brook',
        name2: 'Jisub Kim',
        designation2: 'Co-Funder and CTO',
        description2: 'Hankuk University of Foreign Studies',
    },
    ko: {
        name1: '구성윤',
        designation1: 'Founder, CEO',
        description1: '뉴욕주립대학교 스토니브룩',
        name2: '김지섭',
        designation2: 'Co-Funder, CTO',
        description2: '한국외국어대학교',
    }
};

const TeamOne = ({column , teamStyle}) => {
    const { t, nt } = usePageTranslations(translations); 
    const teamData = [
        {
            image: 'seongyunku',
            name: nt('name1'),
            designation: nt('designation1'),
            location: 'South Korea', 
            description: nt('description1'),
            socialNetwork: [
                {
                    icon: <FiGlobe />,
                    url: 'https://syk.sk/'
                },
                {
                    icon: <FiLinkedin />,
                    url: 'https://www.linkedin.com/in/seongyun-ku-5bbaab140/'
                },
                {
                    icon: <FiGithub />,
                    url: 'https://github.com/specterku'
                },
            ]
           
        },
        {
            image: 'jisubkim',
            name: nt('name2'),
            designation: nt('designation2'),
            location: 'South Korea', 
            description: nt('description2'),
            socialNetwork: [
                {
                    icon: <FiGlobe />,
                    url: 'https://jisub.kim/'
                },
                {
                    icon: <FiLinkedin />,
                    url: 'https://www.linkedin.com/in/%EC%A7%80%EC%84%AD-%EA%B9%80-b525b2189/'
                },
                {
                    icon: <FiGithub />,
                    url: 'https://github.com/kimjisub'
                },
            ]
          
        }
    ];
    
    return (
        <div className="row row--15 justify-content-center">
            {teamData.map((data, index) => (
                <div className={`${column}`} key={index}>
                    <div className={`rn-team ${teamStyle}`}>
                        <ScrollAnimation 
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                            <div className="inner">
                                <figure className="thumbnail">
                                    <img src={`/images/team/${data.image}.jpg`} alt="Corporate React Template" />
                                </figure>
                                <figcaption className="content">
                                    <h2 className="title">{data.name}</h2>
                                    <h6 className="subtitle theme-gradient">{data.designation}</h6>
                                    <span className="team-form">
                                        <img src="/images/team/location.svg" alt="Corporate React Template" />
                                        <span className="location">{data.location}</span>
                                    </span>
                                    <p className="description">{data.description}</p>

                                    <ul className="social-icon social-default icon-naked mt--20" >
                                        {data.socialNetwork.map((social, index) =>
                                            <li key={index}><a target="_blank" href={`${social.url}`}>{social.icon}</a></li>
                                        )}
                                    </ul>
                                </figcaption>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default TeamOne;
