import PropTypes from "prop-types";
import React from 'react'
import { usePageTranslations } from '../utils/translation';

const translations = {
	en: {
        title: 'Alpaon',
        des: 'Discover our latest services and solutions at Alpaon. Empowering your business growth with cutting-edge technology.'
	},
	ko: {
        title: '알파온',
        des: '알파온에서 제공하는 최신 서비스와 솔루션을 확인하세요. 최첨단 기술로 귀사의 비즈니스 성장을 지원합니다.'
	},
};

const SEO = ( {title} ) => {
    const { t, nt } = usePageTranslations(translations);  
    return (
        <>
            <meta charSet="utf-8" />
            <title>{nt('title')} - {title}</title>
            <meta name="robots" content="index, follow" />
            <meta name="description" content={nt('des')} />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;


