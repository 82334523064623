import React, { Component } from "react";

class GoogleMapStyle extends Component {
  render() {
    return (
      // Important! Always set the container height explicitly

      <div className="google-map-style-1">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3170.9799834459773!2d126.64580027707582!3d37.36665050668875!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b765949a17d7d%3A0x3e416215c4d0688c!2z7J247LKc6rSR7Jet7IucIOyXsOyImOq1rCDshqHrj4Trr7jrnpjroZwgMzA!5e0!3m2!1sko!2skr!4v1715490665078!5m2!1sko!2skr"
          width="100%"
          height="100%"
          style={{ borderRadius: "10px", position: "relative", border: "0" }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    );
  }
}

export default GoogleMapStyle;
